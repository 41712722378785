.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  background: transparent;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.swiper-button-prev,
.swiper-button-next {
  color: #ffffff;
}

.swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  background: var(--swiper-pagination-bullet-inactive-color, #ffffff);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 1);
}
.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #FF874F;
}
