@font-face {
  font-family: 'Specter';
  font-style: bold;
  font-display: swap;
  font-weight: 600;
  src: url('./Specter-Bold.woff2') format('woff2'), url('./Specter-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Specter';
  font-style: black;
  font-display: swap;
  font-weight: 800;
  src: url('./Specter-Black.woff2') format('woff2'), url('./Specter-Black.woff') format('woff');
}
