@font-face {
  font-family: 'Calibre';
  font-style: light;
  font-display: swap;
  font-weight: 300;
  src: url('./Calibre-Light.woff2') format('woff2'), url('./Calibre-Light.woff') format('woff');
}

@font-face {
  font-family: 'Calibre';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('./Calibre-Regular.woff2') format('woff2'), url('./Calibre-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Calibre';
  font-style: bold;
  font-display: swap;
  font-weight: 700;
  src: url('./Calibre-Bold.woff2') format('woff2'), url('./Calibre-Bold.woff') format('woff');
}
