* {
  box-sizing: border-box;
  font-family: Calibre, Helvetica Neue, Helvetica, sans-serif;
}

html {
  scroll-padding-top: 80px;
}

body {
  font-size: 12px;
}

@media screen and (min-width: 991px) {
  html {
    scroll-padding-top: 109px;
  }

  body {
    font-size: 1vw;
  }
}

@media only screen and (min-width: 1700px) {
  body {
    font-size: 17px;
  }
}

a {
  text-decoration: none;
}

button {
  outline: none;
  border: none;
  background: none;
}

li {
  color: #444248;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #444248;
}

h1 {
  font-size: 3.2em;
  font-weight: 700;
}
h2 {
  font-size: 2.8em;
  font-weight: 700;
}
h3 {
  font-size: 2.4em;
  font-weight: 600;
}
h4 {
  font-size: 2em;
  font-weight: 600;
}
h5 {
  font-size: 1.8em;
  font-weight: 600;
}
h6 {
  font-size: 1.6em;
  font-weight: 600;
}

strong {
  color: #444248;
}

p {
  font-size: 1.4em;
  line-height: 1.5;
  color: #444248;
}

a {
  color: #6629d6;
  font-weight: 600;
  font-size: 1.4em;
}

div,
footer,
a,
p,
li,
label,
span,
input,
textarea,
select,
b,
strong,
i,
em,
code,
blockquote,
pre,
small,
sub,
sup,
mark,
q,
s,
strike,
u,
abbr {
  font-family: Calibre, Helvetica Neue, Helvetica, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: Specter, Helvetica Neue, Helvetica, sans-serif;
}
