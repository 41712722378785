.Top10Vehicles .swiper {
  width: 100%;
  height: 100%;
}

.Top10Vehicles .swiper-slide {
  text-align: center;
  background: transparent;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Top10Vehicles .swiper-slide img {
  display: block;
  width: 300px;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
}
@media only screen and (max-width: 1496px){
  .Top10Vehicles .swiper-slide img {
    width: 260px;
  }
}
@media only screen and (max-width: 1280px){
  .Top10Vehicles .swiper-slide img {
    width: 240px;
  }
} 
@media only screen and (max-width: 502px){
  .Top10Vehicles .swiper-slide img {
    width: 300px;
  }
  .Top10Vehicles .swiper-slide {
    width: 80%;
  } 
} 

.Top10Vehicles .swiper-button-prev,
.Top10Vehicles .swiper-button-next {
  color: #333;
}

.Top10Vehicles .swiper-button-prev,
.Top10Vehicles .swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, 0px);
  right: auto;
}
.Top10Vehicles .swiper-button-next,
.Top10Vehicles .swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 0px);
  left: auto;
}

.Top10Vehicles .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  background: var(--swiper-pagination-bullet-inactive-color, #ebeeef);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 1);
}
.Top10Vehicles .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #FF874F;
}

