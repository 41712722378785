.Comparison .swiper {
  width: 100%;
  height: 100%;
}

.Comparison .swiper-slide {
  text-align: center;
  background: transparent;
  text-align: left;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.Comparison .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Comparison .swiper-slide .VehicleVersusImg {
  width: 80px;
  height: 80px;
  margin: -30px 0 0 -90px;
}

.Comparison .swiper-slide .VehicleImgTop {
  width: auto;
  height: 190px;
  margin-left: 20px;
}

.Comparison .swiper-slide .HeaderImg {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

@media only screen and (max-width: 1440px){
  .Comparison .swiper-slide .VehicleVersusImg {
    width: 70px;
    height: 70px;
    margin: -30px 0 0 -60px;
  }
  .Comparison .swiper-slide .VehicleImgTop {
    width: auto;
    height: 180px;
    margin-left: 20px;
  }
} 
@media only screen and (max-width: 1300px){
  .Comparison .swiper-slide .VehicleVersusImg {
    width: 70px;
    height: 70px;
    margin: -30px 0 0 -80px;
  }
  .Comparison .swiper-slide .VehicleImgTop {
    width: auto;
    height: 140px;
    margin-left: 20px;
    margin-top: 10px;
  }
} 
@media only screen and (max-width: 1024px){
  .Comparison .swiper-slide .VehicleVersusImg {
    width: 60px;
    height: 60px;
    margin: -30px 0 0 -50px;
  }
  .Comparison .swiper-slide .VehicleImgTop {
    width: auto;
    height: 140px;
    margin-left: 20px;
    margin-top: 10px;
  }
} 
@media only screen and (max-width: 993px){
  .Comparison .swiper-slide .VehicleVersusImg {
    width: 70px;
    height: 70px;
    margin: -30px 0 0 -90px;
  }
  .Comparison .swiper-slide .VehicleImgTop {
    width: auto;
    height: 150px;
    margin-left: 20px;
    margin-top: 0;
  }
} 
@media only screen and (max-width: 768px){
  .Comparison .swiper-slide .VehicleVersusImg {
    width: 60px;
    height: 60px;
    margin: -20px 0 0 -80px;
  }
  .Comparison .swiper-slide .VehicleImgTop {
    width: auto;
    height: 130px;
    margin-left: 20px;
    margin-top: 20px;
  }
}
@media only screen and (max-width: 502px){
  .Comparison .swiper-slide .VehicleVersusImg {
    width: 70px;
    height: 70px;
    margin: -30px 0 0 -50px;
  }
  .Comparison .swiper-slide .VehicleImgTop {
    width: auto;
    height: 160px;
    margin-left: 40px;
    margin-top: -10px;
  }
} 
@media only screen and (max-width: 430px){
  .Comparison .swiper-slide .VehicleVersusImg {
    display: none;
    width: 50px;
    height: 50px;
    margin: -30px 0 0 -40px;
  }
  .Comparison .swiper-slide .VehicleImgTop {
    width: auto;
    height: 150px;
    margin-left: 20px;
    margin-top: -5px;
  }
} 

.Comparison .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  background: var(--swiper-pagination-bullet-inactive-color, #ebeeef);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 1);
}
.Comparison .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #FF874F;
}

